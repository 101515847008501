import * as Stowage from "firebase/storage";
import { useStorageDownloadURL } from "reactfire";
import { Avatar } from "@mui/material";

function PawnBotAvatar(props) {
  const { id, storage } = props;
  const catRef = Stowage.ref(
    storage,
    `pawnbots/releases/phase-6/${(id || "0") + ".jpg"}`
  );

  const { status, data: imageURL } = useStorageDownloadURL(catRef);

  if (status === "loading") {
    return <Avatar />;
  }

  return (
    <Avatar
      alt="Pawn Bot"
      sx={{ width: 72, height: 72 }}
      src={imageURL}
      className="pawnBotAvatar"
    />
  );
}

export default PawnBotAvatar;

import {
  Box,
  Dialog,
  DialogTitle,
  CircularProgress,
  Stack,
  Link,
} from "@mui/material";

function TransactionDialog(props) {
  const { onClose, open, transactionHash } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle variant="h3" color={"secondary"}>
        Transaction Pending
      </DialogTitle>
      <Stack
        sx={{ padding: 2, alignContent: "center", alignItems: "center" }}
        spacing={0}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}
        >
          <CircularProgress color="success" />
        </Box>
        <Link
          href={`https://www.etherscan.io/tx/${transactionHash}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "white", textDecoration: "underline" }}
        >
          View on block explorer.
        </Link>
      </Stack>
    </Dialog>
  );
}
export default TransactionDialog;

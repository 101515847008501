import "../App.css";
import { ref } from "firebase/database"; // Firebase v9+
import { useMetaMask } from "metamask-react";
import { useDatabaseObjectData } from "reactfire";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { httpsCallable } from "firebase/functions";
import { ethers } from "ethers";
import Addresses from "../contracts/addresses/index";
import BotsAbi from "../contracts/abis/bots_abi.json";
import DistributorAbi from "../contracts/abis/distributor_abi.json";
import ScrollDialog from "../components/ScrollDialog";
import SuccessDialog from "../components/SuccessDialog";
import TermsDialog from "../components/TermsDialog";
import TransactionDialog from "../components/TransactionDialog";
import MainFurnaceSection from "../components/MainFurnaceSection";
import ButtonAppBar from "../components/ButtonAppBar";

function AppContainer(props) {
  const { functions, database, storage } = props;
  const localStorageUserAccepted = window.localStorage.getItem("userAccepted");
  const botsRef = ref(database, `public/collection/releases/phase-6/`);
  const { data: allBots } = useDatabaseObjectData(botsRef);
  const [pawnBotIDS, setPawnBotIDS] = useState(null);
  const [pbIDArray, setPbIDArray] = useState(null);
  const [modeledAccountBots, setModeledAccountBots] = useState(null);
  const [usedBotIDS, setUsedBotIDS] = useState(null);
  const getPawnBotIDS = httpsCallable(functions, "get_pawnbot_ids");
  const get_used_ids = httpsCallable(
    functions,
    "get_pending_modification_bot_ids"
  );
  const [raritySniperURL, setRaritySniperURL] = useState(
    "https://raritysniper.com/pawn-bots"
  );
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openScroll, setOpenScroll] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const { status: metamask_status, connect, account, ethereum } = useMetaMask();
  const [botsInShoppingCart, setBotsInShoppingCart] = useState([]);
  const [openTerms, setOpenTerms] = useState(false);

  const [userAccepted, setUserAccepted] = useState(() => {
    return localStorageUserAccepted
      ? JSON.parse(localStorageUserAccepted)
      : false;
  });

  const provider = useMemo(
    () => new ethers.providers.Web3Provider(window.ethereum),
    []
  );

  const botsContract = useMemo(
    () =>
      new ethers.Contract(
        Addresses.botsContractAddress,
        BotsAbi,
        provider.getSigner()
      ),
    [provider]
  );

  const distributorContract = new ethers.Contract(
    Addresses.distributorContractAddress,
    DistributorAbi,
    provider.getSigner()
  );

  useEffect(() => {
    if (pbIDArray && allBots) {
      if (pbIDArray === undefined) {
        // console.log("pbIDArray:", pbIDArray);
        return;
      }
      if (allBots === undefined) {
        // console.log("allBots:", allBots);

        return;
      }
      // console.log("allBots:", allBots)
      const allBotsModeled = Object.values(allBots).map((bot, index) => {
        // console.log(bot);
        if (bot === "pawnbots") {
          return null;
        }
        if (!bot.attributes) {
          return null;
        }
        bot.attributes.push({ trait_type: "id", value: index });
        return bot;
      });

      const accountBots = allBotsModeled
        .filter((botModel, index) => {
          return pbIDArray.includes(index);
        })
        .map((bot) => {
          return rebuildAttributeObject(bot.attributes);
        });
      if (JSON.stringify(modeledAccountBots) !== JSON.stringify(accountBots)) {
        setModeledAccountBots(accountBots);
      }
      // console.log("accountBots:", accountBots);
    }
  }, [pbIDArray, allBots, modeledAccountBots]);

  const saveStateToLocalStorage = useCallback(() => {
    window.localStorage.setItem("userAccepted", JSON.stringify(userAccepted));
  }, [userAccepted]);

  useEffect(
    function beforeunload() {
      try {
        window.addEventListener("beforeunload", saveStateToLocalStorage);
        return () =>
          window.removeEventListener("beforeunload", saveStateToLocalStorage);
      } catch (e) {}
    },
    [saveStateToLocalStorage]
  );

  function rebuildAttributeObject(input) {
    let output = {};
    input.forEach((attribute) => {
      output[`${attribute.trait_type.replace(" ", "_")}`] = attribute.value;
    });
    return output;
  }

  async function fetchPawnBotIDS(wallet_address) {
    // console.log("wallet_address:", wallet_address);
    const pawnBotIDSResponse = await getPawnBotIDS({
      wallet_address: wallet_address,
    });
    // console.log("🤡🤡🤡", pawnBotIDSResponse.data);
    setPawnBotIDS(pawnBotIDSResponse.data);
    const arr = Object.values(pawnBotIDSResponse.data).map((id) => {
      return Number(id);
    });
    setPbIDArray(arr);
    buildRaritySniperURL(arr);
    const used_ids = await get_used_ids();
    // console.log("🤮🤮🤮🤮", used_ids.data);
    setUsedBotIDS(used_ids.data);
    console.log(used_ids.data.length);
    // buildBotLinksObject(pawnBotIDSResponse.data, used_ids.data);
    // setUsed(used_ids.data);
  }

  function buildRaritySniperURL(pawnBotsArr) {
    let url = "https://raritysniper.com/pawn-bots?nftId=";
    if (pawnBotsArr.length > 0) {
      for (let index = 0; index < pawnBotsArr.length; index++) {
        const botID = pawnBotsArr[index];
        const urlString = index === 0 ? `${botID}` : `&nftId=${botID}`;
        url = url.concat(urlString);
      }
      setRaritySniperURL(url);
    }
  }

  const burnNFT = async () => {
    try {
      if (ethereum) {
        const approved = await botsContract.isApprovedForAll(
          account,
          distributorContract.address
        );
        if (!approved) {
          const approveTxn = await botsContract.setApprovalForAll(
            distributorContract.address,
            true
          );
          setTransactionHash(approveTxn.hash);
          setOpen(true);

          botsContract.on("ApprovalForAll", (sender, operator, approved) => {
            console.log("ApprovalForAll", sender, operator, approved);
          });
          await approveTxn.wait();
          setOpen(false);
          console.log(
            `Mined, see transaction: https://www.etherscan.io/tx/${approveTxn.hash}`
          );
        }
        setDisableInput(true);
        // botsInShoppingCart
        const burnTxn = await distributorContract.distribute(
          botsInShoppingCart
        );
        setTransactionHash(burnTxn.hash);
        setOpen(true);

        // await submitForgeRequest(burnTxn.hash)
        await burnTxn.wait();

        console.log(
          `Mined, see transaction: https://www.etherscan.io/tx/${burnTxn.hash}`
        );

        setOpen(false);
        await timeout(333); //for .3 sec delay
        setOpenSuccess(true);
        setDisableInput(false);
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
      setDisableInput(false);
    }
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async function submitForgeRequestClicked() {
    // await spendBurnToken()
    await burnNFT();
    // printModel()
  }

  // getting errors when these are null and it's crashing the page
  function modelIsValid() {
    if (botsInShoppingCart.length < 1) {
      return false;
    }
    return true;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = async () => {
    setOpenSuccess(false);
    setBotsInShoppingCart([]);
    await fetchPawnBotIDS(account);
  };

  const handleTermsClose = async () => {
    setOpenTerms(false);
    setUserAccepted(true);
    connect();
  };

  const handleSelectionClickOpen = (event) => {
    // console.log("event", event.currentTarget.id);
    // let burn = event.currentTarget.id === "burn"
    // setBurnDialog(burn)
    setOpenScroll(true);
  };

  const handleSelectionClose = () => {
    setOpenScroll(false);
  };
  // TODO: make it so the active selection is not lost.

  return (
    <>
      <ButtonAppBar
        handleClose={handleClose}
        metamask_status={metamask_status}
        connect={connect}
        account={account}
        fetchPawnBotIDS={fetchPawnBotIDS}
        raritySniperURL={raritySniperURL}
        openTerms={openTerms}
        setOpenTerms={setOpenTerms}
        userAccepted={userAccepted}
      />
      <MainFurnaceSection
        storage={storage}
        disableInput={disableInput}
        handleSelectionClickOpen={handleSelectionClickOpen}
        botsInShoppingCart={botsInShoppingCart}
        metamask_status={metamask_status}
        modelIsValid={modelIsValid}
        submitForgeRequestClicked={submitForgeRequestClicked}
      />
      <TransactionDialog
        open={open}
        onClose={handleClose}
        transactionHash={transactionHash}
      />
      <SuccessDialog
        open={openSuccess}
        onClose={handleSuccessClose}
        ethereum={ethereum}
      />
      <TermsDialog open={openTerms} onClose={handleTermsClose} />
      <ScrollDialog
        onClose={handleSelectionClose}
        botsInShoppingCart={botsInShoppingCart}
        setBotsInShoppingCart={setBotsInShoppingCart}
        setOpenScroll={setOpenScroll}
        pawnBotIDS={pawnBotIDS}
        modeledAccountBots={modeledAccountBots}
        openScroll={openScroll}
        usedBotIDS={usedBotIDS}
        storage={storage}
      />
    </>
  );
}

export default AppContainer;

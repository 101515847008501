import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Button,
  Chip,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import MenuIcon from "@mui/icons-material/Menu";
import DoneIcon from "@mui/icons-material/Done";

let lastWalletToFetchPawnBotIds = "";

function ButtonAppBar(props) {
  const {
    handleClose,
    metamask_status,
    connect,
    account,
    fetchPawnBotIDS,
    raritySniperURL,
    setOpenTerms,
    userAccepted,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function MetaMaskConnect() {
    if (metamask_status === "initializing")
      return (
        <Typography color="secondary">
          Synchronization with MetaMask ongoing...
        </Typography>
      );

    if (metamask_status === "unavailable")
      return (
        <Typography color="secondary">MetaMask not available :(</Typography>
      );

    if (metamask_status === "notConnected")
      return (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleConnectClick}
        >
          Connect to MetaMask
        </Button>
      );

    if (metamask_status === "connecting")
      return <Typography color="secondary">Connecting...</Typography>;

    if (metamask_status === "connected" && account) {
      if (lastWalletToFetchPawnBotIds !== account) {
        lastWalletToFetchPawnBotIds = account;
        fetchPawnBotIDS(account);
      }
      // console.log(ethereum);
      return (
        <Chip
          label={account}
          icon={<DoneIcon />}
          variant="outlined"
          color="secondary"
        />
      );
    }
    return null;
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEtherscan = () => {
    window.open(
      "https://etherscan.io/address/0x28f0521c77923f107e29a5502a5a1152517f9000"
    );
    setAnchorEl(null);
  };
  const handleStore = () => {
    window.open("https://store.pawnbots.com/");
    setAnchorEl(null);
  };
  const handleForge = () => {
    window.open("https://forge.pawnbots.com/");
    setAnchorEl(null);
  };
  const handleOpenSea = () => {
    window.open("https://opensea.io/collection/pawnbots");
    setAnchorEl(null);
  };

  const handleRaritySniper = () => {
    window.open(`${raritySniperURL}`);
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleConnectClick = () => {
    if (!userAccepted) {
      setOpenTerms(true);
    } else {
      connect();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <ClickAwayListener onClickAway={handleClickAway}>
            <IconButton
              size="large"
              edge="start"
              color="secondary"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
          </ClickAwayListener>
          <Typography
            sx={{ flexGrow: 1, textAlign: "left" }}
            variant="h1"
            color="secondary"
          >
            Pawn Bots Furnace
          </Typography>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Typography variant="h3" color="secondary" sx={{ ml: 2 }}>
              Helpful Links
            </Typography>
            <Divider sx={{ mt: 1 }} />
            <MenuItem onClick={handleStore}>
              <LaunchIcon fontSize="small" />
              &nbsp;Pawn Bots Upgrades
            </MenuItem>
            <MenuItem onClick={handleForge}>
              <LaunchIcon fontSize="small" />
              &nbsp;Pawn Bots Forge
            </MenuItem>
            <MenuItem onClick={handleRaritySniper}>
              <LaunchIcon fontSize="small" /> &nbsp;Rarity Sniper
            </MenuItem>
            <MenuItem onClick={handleEtherscan}>
              <LaunchIcon fontSize="small" />
              &nbsp;Etherscan
            </MenuItem>
            <MenuItem onClick={handleOpenSea}>
              <LaunchIcon fontSize="small" /> &nbsp;OpenSea
            </MenuItem>
          </Menu>
          <MetaMaskConnect />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ButtonAppBar;

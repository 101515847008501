import "./App.css";
import { getDatabase } from "firebase/database"; // Firebase v9+
import { getStorage } from "firebase/storage";
import {
  DatabaseProvider,
  useFirebaseApp,
  StorageProvider,
  FunctionsProvider,
} from "reactfire";
import { CssBaseline } from "@mui/material";
import React from "react";
import { getFunctions } from "firebase/functions";
import AppContainer from "./containers/AppContainer";

function App() {
  const database = getDatabase(useFirebaseApp());
  const storage = getStorage(useFirebaseApp());
  const functions = getFunctions(useFirebaseApp(), "us-central1");

  return (
    <div className="App">
      <CssBaseline />
      <DatabaseProvider sdk={database}>
        <StorageProvider sdk={storage}>
          <FunctionsProvider sdk={functions}>
            <AppContainer
              database={database}
              functions={functions}
              storage={storage}
            />
          </FunctionsProvider>
        </StorageProvider>
      </DatabaseProvider>
    </div>
  );
}

export default App;

import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  CardActions,
  Button,
  AvatarGroup,
} from "@mui/material";
import * as Stowage from "firebase/storage";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import PawnBotAvatar from "./PawnBotAvatar";
import { useStorageDownloadURL } from "reactfire";

function MainFurnaceSection(props) {
  const {
    storage,
    disableInput,
    handleSelectionClickOpen,
    botsInShoppingCart,
    metamask_status,
    modelIsValid,
    submitForgeRequestClicked,
  } = props;

  function AttributeSelection() {
    if (metamask_status === "connected") {
      return (
        <>
          <br />
          <ForgeButton />
          <br />
          <br />
        </>
      );
    }
    return null;
  }

  function ForgeButton() {
    return (
      <Button
        variant="outlined"
        startIcon={<WhatshotIcon />}
        disabled={!modelIsValid() || disableInput}
        onClick={submitForgeRequestClicked}
        color="secondary"
      >
        {/* {burnApproved ? "Submit Forge Request" : "Approve Burn Contract"} */}
        Burn Bots
      </Button>
    );
  }

  function ShoppingCartAvatars() {
    const total = botsInShoppingCart.length;
    const max = 5;

    return (
      <AvatarGroup onClick={handleSelectionClickOpen} total={total}>
        {botsInShoppingCart
          .filter((bot_id, index) => {
            return index <= max;
          })
          .map((bot_id) => (
            <PawnBotAvatar id={bot_id} key={bot_id} storage={storage} />
          ))}
        ;
      </AvatarGroup>
    );
  }

  function AtariImage() {
    const path = "static/burn_token.gif";
    const catRef = Stowage.ref(storage, path);
    // const catRef = Stowage.ref(storage, `static/ceramic_white_atari.png`)
    // eslint-disable-next-line
    const { status, data: imageURL } = useStorageDownloadURL(catRef);
    const dimension = 400;
    return status !== "loading" ? (
      <CardMedia
        component="img"
        height={dimension}
        image={imageURL}
        alt="white ceramic atari pawn bot"
      />
    ) : (
      <Skeleton variant="circular" width={dimension} height={dimension} />
    );
  }

  function MultiActionAreaCard() {
    const path = "static/soldout.png";
    const catRef = Stowage.ref(storage, path);

    // eslint-disable-next-line
    const { status, data: imageURL } = useStorageDownloadURL(catRef);

    return (
      <Card sx={{ minWidth: 400 }}>
        <AtariImage />
        <CardContent>
          <Typography variant="h2" align="left" color="secondary">
            Pawn Bots Furnace
          </Typography>
          <Typography align="left">
            Burn multiple Pawn Bots in a single transaction. This cannot be
            undone, proceed with caution! You receive BURN token according the
            current emissions rate.
          </Typography>
        </CardContent>
        <CardActions>
          {metamask_status === "connected" ? (
            <Button
              size="medium"
              disabled={disableInput}
              onClick={handleSelectionClickOpen}
              variant="outlined"
              color="secondary"
            >
              Select Bots To Burn
            </Button>
          ) : null}
        </CardActions>
      </Card>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={4} />
        <Grid xs={4}>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: 2,
            }}
          >
            <MultiActionAreaCard />
            <br />
            <ShoppingCartAvatars />
          </Box>
        </Grid>
      </Grid>
      {/* <div>{JSON.stringify(botsInShoppingCart)}</div> */}
      <Typography>
        {botsInShoppingCart.length} Pawn Bots in the furnace
      </Typography>
      <Typography>
        BURN Distribution Rate: 0.9 BURN token per forged/burned bot
      </Typography>
      <AttributeSelection />
    </>
  );
}
export default MainFurnaceSection;

import { Button, Dialog, DialogTitle, Stack, Link } from "@mui/material";

function TermsDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} disableBackdropClick>
      <DialogTitle
        variant="h3"
        color="success"
        sx={{
          padding: 4,
          alignContent: "center",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        By continuing, I accept Hifi's{" "}
        <Link
          href="https://hifi.finance/terms"
          target="_blank"
          rel="noreferrer"
          sx={{ color: "white", textDecoration: "underline" }}
        >
          Terms of Service.
        </Link>
      </DialogTitle>
      <Stack
        sx={{ padding: 2, alignContent: "center", alignItems: "center" }}
        spacing={0}
      >
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
          sx={{ marginBottom: 2 }}
        >
          I Accept
        </Button>
      </Stack>
    </Dialog>
  );
}

export default TermsDialog;

import { Box, Button, Dialog, DialogTitle, Stack } from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot";

function SuccessDialog(props) {
  const { onClose, open, ethereum } = props;

  const handleClose = () => {
    onClose();
  };

  const handleAddTokenToMetamask = async (event) => {
    // console.log("event", event.currentTarget.id);
    // let burn = event.currentTarget.id === "burn";
    // setBurnDialog(burn);
    // setOpenSelection(true);

    const tokenAddress = "0xDeadb071ab55db23Aea4cF9b316faa8B7Bd26196";
    const tokenSymbol = "BURN";
    const tokenDecimals = 18;
    const tokenImage = "https://static.pawnbots.com/PB%20Burn%20Token.png";

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Token Added To Metamask.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle variant="h3" color="secondary">
        BURN Successful!
      </DialogTitle>
      <Stack
        sx={{ padding: 2, alignContent: "center", alignItems: "center" }}
        spacing={0}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}
        >
          <WhatshotIcon sx={{ fontSize: 100 }} />
        </Box>
        <Button
          variant="outlined"
          sx={{ marginBottom: 2 }}
          color="secondary"
          onClick={handleAddTokenToMetamask}
          id="mm"
        >
          Add BURN Token to Metamask
        </Button>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Dismiss
        </Button>
      </Stack>
    </Dialog>
  );
}

export default SuccessDialog;

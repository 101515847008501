import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    text: {
      primary: "#fff",
    },
    primary: {
      main: "#000",
      darker: "#053e85",
      light: "#fff",
    },
    secondary: {
      main: "#e7c446",
    },
    success: {
      main: "#fff",
    },
    dark: {
      main: "#101010",
      contrastText: "#fff",
    },
    background: {
      paper: "#000",
    },
    divider: "rgb(231, 196, 70, 0.5)",
  },
  typography: {
    h1: {
      fontFamily: "ChicagoFLF",
      fontSize: 30,
    },
    h2: {
      fontFamily: "ChicagoFLF",
      fontSize: 24,
    },
    h3: {
      fontFamily: "ChicagoFLF",
      fontSize: 20,
    },
    body1: {
      fontSize: 14,
    },
  },
});

import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  DialogActions,
  DialogContent,
} from "@mui/material";
import PawnBotAvatar from "./PawnBotAvatar";
// import "./ScrollDialog.css";

function ScrollDialog(props) {
  const {
    onClose,
    setBotsInShoppingCart,
    setOpenScroll,
    pawnBotIDS,
    modeledAccountBots,
    openScroll,
    usedBotIDS,
    storage,
  } = props;

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClose = () => {
    setBotsInShoppingCart(checked);
    setOpenScroll(false);
    setChecked([]);
    onClose();
  };

  if (pawnBotIDS === null || modeledAccountBots === null) {
    return <span></span>;
  }

  return (
    <div>
      <Dialog
        open={openScroll}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle variant="h3" color="secondary">
          Select Bots To Burn
        </DialogTitle>

        <DialogContent>
          <List sx={{ pt: 0 }}>
            {modeledAccountBots
              .filter((bot, index) => {
                // console.log(`${bot.id}`);
                if (usedBotIDS && usedBotIDS.includes(`${bot.id}`)) {
                  return false; //skip
                }

                return true;
                // return true;
              })
              .map((bot) => (
                <ListItem
                  button
                  onClick={handleToggle(bot.id)}
                  key={bot.id}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(bot.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      color="success"
                      sx={{ color: "white" }}
                      inputProps={{ "aria-labelledby": bot.id }}
                    />
                  </ListItemIcon>
                  <PawnBotAvatar id={bot.id} storage={storage} />
                  <ListItemText primary={"Bot #" + bot.id} sx={{ ml: 2 }} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScrollDialog;

import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from "reactfire";
import { ThemeProvider } from "@mui/material/styles";
import { MetaMaskProvider } from "metamask-react";
import { theme } from "./theme";

const firebaseConfig = {
  apiKey: "AIzaSyAP_5ZOs1VImb6mNWKJQ_fwh8aEbrPSAtA",
  authDomain: "meltandforge.firebaseapp.com",
  projectId: "meltandforge",
  storageBucket: "meltandforge.appspot.com",
  messagingSenderId: "88509008008",
  appId: "1:88509008008:web:7eb2714f2c6b2b411fb73d",
  measurementId: "G-87KWWBLL29",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <MetaMaskProvider>
          <App />
        </MetaMaskProvider>
      </ThemeProvider>
    </React.StrictMode>
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
